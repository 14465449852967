import React, {Suspense, lazy} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ProductCategory from './pages/productCategory/ProductCategory';
import ProductDetail from './pages/productDetail/ProductDetail';

// Lazy loading components and pages
const Home = lazy(() => import("./pages/home/Home"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const Navbar = lazy(() => import("./components/navbar/Navbar"));
const Categories = lazy(() => import("./components/categories/Categories"));
const Footer = lazy(() => import("./components/footer/Footer"));
const ComingSoon = lazy(() => import("./pages/comingSoon/ComingSoon"));
const Login = lazy(() => import("./components/login/Login"));
const FeaturedList = lazy(() => import("./pages/featuredList/FeaturedList"));

const App = () => {
    return(
        <React.Fragment>
            <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}>
                    <Navbar />
                    <Categories />
                    <Routes>
                        <Route index element={<Home />}/>
                        <Route path='/comingSoon' element={<ComingSoon />}/>
                        <Route path='/login' element={<Login />}/>
                        <Route path='/featured/:productName' element={<FeaturedList />}/>
                        <Route path='/products/:category/:subcategory' element={<ProductCategory />}/>
                        <Route path='/products/:category/:subcategory/:productId' element={<ProductDetail />} />
                        <Route path='/industries/:subcategory' element={<ProductCategory />}/>
                        <Route path='/industries/:subcategory/:productId' element={<ProductDetail />} />
                        <Route path='*' element={<NotFound />}/>
                    </Routes>
                    <Footer />
                </Suspense>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
