import mhImage from "./images/featuredIcons/mobileHydraulicsIcon.png";
import ihImage from "./images/featuredIcons/industrialHydraulicsIcon.png";
import pnImage from "./images/featuredIcons/pneumaticsIcon.png";
import filImage from "./images/featuredIcons/filterIcon.png";
import hfImage from "./images/featuredIcons/houseFittingIcon.png";
import lubImage from "./images/featuredIcons/lubricationIcon.png";
import fpImage from "./images/featuredIcons/fluidProcessIcon.png";
import isImage from "./images/featuredIcons/industrialSupplyIcon.png";
import amcImage from "./images/featuredIcons/automationAndMotionControlIcon.png";
import adImage from "./images/featuredIcons/applicationDesignIcon.png";
import indImage from "./images/featuredIcons/industriesIcon.png";
import oImage from "./images/featuredIcons/otherIcon.png";

export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "Contact",
        path: '/comingSoon'
    },
    {
        name: "Login",
        path: '/login'
    },
    {
        name: "Products",
        path: '/#featured'
    },
    {
        name: "Industries",
        path: '/comingSoon'
    }
];


export const items = [
    {
        id: 1,
        name: 'Pumps',
        site: 'comingSoon',
        category: 'Fruit'
    },

    {
        id: 2,
        name: 'Screws',
        site: 'comingSoon',
        category: 'Fruit'
    },

    {
        id: 3,
        name: 'Devices',
        site: 'comingSoon',
        category: 'Vegetable'
    }

];

// src/data.js

export const products = [
    {
        title: "Mobile Hydraulics",
        items: ["Pumps", "Motors", "Valves", "Manifolds", "Engine Cooling Packages", "Mobile Electronics"]
    },
    {
        title: "Industrial Hydraulics",
        items: ["Pumps", "Motors", "Valves", "Actuators, Cylinders, & Cylinder Accessories", "Hydraulic Oil Cooling Units", "Power Units"]
    },
    {
        title: "Pneumatics",
        items: ["Valves", "Valve Manifolds", "Actuators, Cylinders & Grippers", "Air Prep", "Vacuum & Vacuum Components", "Fittings"]
    },
    {
        title: "Filtration",
        items: ["Bag Filters", "Cartridge & Spin-On Filters", "Air & Gas Filters", "Hydraulic, Lube, and Fuel Filters", "Dryers & Moisture Removal Solutions", "Filter Carts & Off-Line Filtration"]
    },
    {
        title: "Hose And Fittings",
        items: ["Hydraulic Hose, Fittings & Adapters", "Industrial Hose & Fittings", "Welded Metal Hose", "Gaskets", "Couplings & Cam Groove", "Hose Accessories & Tools"]
    },
    {
        title: "Lubrication",
        items: ["Automatic Lubrication Systems (ALS)", "Automated Oil & Grease Injector Systems", "Automated Oil & Grease Progressive Systems", "Controllers", "Dual Line Systems", "Chain Lubrication Systems"]
    },
    {
        title: "Fluid Process",
        items: ["Process Pumps, Diaphragm Pumps & Pumping Systems", "Pipes, Pipe Fittings & Corrosion Resistant Piping Systems", "Mixers", "Measurement Instrumentation & Control", "Fluid Process Filtration", "Tubing, Hose & Tube Fittings"]
    },
    {
        title: "Industrial Supply",
        items: ["Respiratory Safety", "Hand Protection", "Eye & Ear Protection", "Face & Head Protection", "Body & Limb Protection", "Foot Protection"]
    },
    {
        title: "Automation And Motion Control",
        items: ["Variable Frequency & Servo Drives (AC And DC)", "Motors (AC, DC, Servo, Stepper)", "Electric Actuators", "Sensors & Cables", "Gear Boxes & Gear Motors", "Industrial Framing"]
    },
    {
        title: "Other",
        items: [
            "Fabrication",
            "Mining Supply",
            "Hose Kitting & Assembly"
        ]
    }
];

export const services = [
    {
        title: "Service & Repair",
        items: ["Hydraulic Repair", "Electronic Repair", "Mechanical Repair", "Fluid Process Repair"]
    },

    {
        title: "Application Design",
        items: ["Mobile Application Design", "Industrial Application Design"]
    },
    {
        title: "Other",
        items: ["Fabrication", "Mining Supply", "Hose Kitting & Assembly"]
    }
]


export const industrials = [
    {
        name: "Industrial OEM-MRO",
        categories: ["Manufacturing Solutions", "Maintenance Solutions"]
    },
    {
        name: "Mobile Machinery",
        categories: ["Construction Equipment", "Agricultural Machinery"]
    },
    {
        name: "Oil And Gas",
        categories: ["Upstream Solutions", "Downstream Solutions"]
    },
    // Add more industries as needed
];

export const featured = [
    {
        id: 1,
        name: 'Mobile Hydraulics',
        icon: mhImage,
    },
    {
        id: 2,
        name: 'Industrial Hydraulics',
        icon: ihImage,
    },
    {
        id: 3,
        name: 'Pneumatics',
        icon: pnImage,
    },
    {
        id: 4,
        name: 'Filtration',
        icon: filImage,
    },
    {
        id: 5,
        name: 'Hose And Fittings',
        icon: hfImage,
    },
    {
        id: 6,
        name: 'Lubrication',
        icon: lubImage,
    },
    {
        id: 7,
        name: 'Fluid Process',
        icon: fpImage,
    },
    {
        id: 8,
        name: 'Industrial Supply',
        icon: isImage,
    },
    {
        id: 9,
        name: 'Automation And Motion Control',
        icon: amcImage,
    },
    {
        id: 10,
        name: 'Application Design',
        icon: adImage,
    },
    {
        id: 11,
        name: 'Industries',
        icon: indImage,
    },
    {
        id: 12,
        name: 'Other',
        icon: oImage,
    },
];

export const detailedProducts = {
    "mobile-hydraulics": {
        title: "Mobile Hydraulics",
        categories: {
            "Pumps": [
                {
                    id: 1,
                    name: "MP1 Series Mobile Piston Pump",
                    description: "High-pressure piston pump for mobile applications",
                    price: 2499.99,
                    image: "pump.webp"
                },
                {
                    id: 2,
                    name: "MG2 Series Mobile Gear Pump",
                    description: "Robust gear pump for construction equipment",
                    price: 1899.99,
                    image: null
                },
                {
                    id: 3,
                    name: "MV3 Series Variable Displacement Pump",
                    description: "Variable displacement pump for agricultural machinery",
                    price: 3299.99,
                    image: null
                },
                {
                    id: 4,
                    name: "MDC4 Dual-Circuit Pump",
                    description: "Dual-circuit pump for mobile lifting applications",
                    price: 4599.99,
                    image: null
                }
            ],
            "Motors": [
                {
                    id: 1,
                    name: "MMR Series Radial Piston Motor",
                    description: "High-torque radial piston motor",
                    price: 2899.99,
                    image: null
                },
                {
                    id: 2,
                    name: "MMA Series Axial Piston Motor",
                    description: "Compact axial piston motor for mobile drives",
                    price: 2199.99,
                    image: null
                },
                {
                    id: 3,
                    name: "MGM Gear Motor",
                    description: "Efficient gear motor for mobile applications",
                    price: 1699.99,
                    image: null
                }
            ]
        }
    },
    "industrial-hydraulics": {
        title: "Industrial Hydraulics",
        categories: {
            "Pumps": [
                {
                    id: 1,
                    name: "PVH Series High-Pressure Vane Pump",
                    description: "Industrial vane pump for high-pressure applications",
                    price: 3499.99
                },
                {
                    id: 2,
                    name: "PGP Series Heavy-Duty Gear Pump",
                    description: "Reliable gear pump for industrial systems",
                    price: 2799.99
                },
                {
                    id: 3,
                    name: "PAV Series Axial Piston Pump",
                    description: "Variable displacement axial piston pump",
                    price: 4199.99
                }
            ],
            "Motors": [
                {
                    id: 1,
                    name: "IMH Heavy-Duty Hydraulic Motor",
                    description: "Robust motor for industrial applications",
                    price: 3299.99
                },
                {
                    id: 2,
                    name: "IML Low-Speed High-Torque Motor",
                    description: "High-torque motor for industrial machinery",
                    price: 2899.99
                }
            ]
        }
    },
    "pneumatics": {
        title: "Pneumatics",
        categories: {
            "Valves": [
                {
                    id: 1,
                    name: "PV5000 Series Directional Control Valve",
                    description: "High-flow pneumatic directional control valve for industrial automation",
                    price: 449.99
                },
                {
                    id: 2,
                    name: "PSV Series Safety Valve",
                    description: "Safety shutdown valve with redundant monitoring",
                    price: 599.99
                },
                {
                    id: 3,
                    name: "PFV Flow Control Valve",
                    description: "Precision flow control valve for pneumatic systems",
                    price: 299.99
                }
            ],
            "Actuators": [
                {
                    id: 1,
                    name: "PA2000 Series Linear Actuator",
                    description: "Heavy-duty pneumatic linear actuator",
                    price: 799.99
                },
                {
                    id: 2,
                    name: "PR1000 Rotary Actuator",
                    description: "Compact rotary actuator for automated processes",
                    price: 649.99
                },
                {
                    id: 3,
                    name: "PG500 Parallel Gripper",
                    description: "Precision parallel gripper for robotic applications",
                    price: 899.99
                },
                {
                    id: 4,
                    name: "PC150 Compact Cylinder",
                    description: "Space-saving cylinder for confined spaces",
                    price: 249.99
                }
            ],
            "Air Prep": [
                {
                    id: 1,
                    name: "PAF3000 Air Filter",
                    description: "High-efficiency compressed air filtration system",
                    price: 199.99
                },
                {
                    id: 2,
                    name: "PAR2000 Air Regulator",
                    description: "Precision pressure regulation for pneumatic systems",
                    price: 159.99
                },
                {
                    id: 3,
                    name: "PAL1000 Air Lubricator",
                    description: "Automatic air line lubricator for pneumatic tools",
                    price: 129.99
                }
            ]
        }
    },
    "filtration": {
        title: "Filtration",
        categories: {
            "Bag Filters": [
                {
                    id: 1,
                    name: "BF1000 High Flow Bag Filter",
                    description: "Large capacity bag filter for industrial processes",
                    price: 899.99
                },
                {
                    id: 2,
                    name: "BF750 Chemical-Resistant Bag Filter",
                    description: "Chemical-resistant filter for aggressive media",
                    price: 1199.99
                },
                {
                    id: 3,
                    name: "BF500 Food Grade Bag Filter",
                    description: "FDA-compliant filter for food and beverage applications",
                    price: 749.99
                }
            ],
            "Hydraulic Filters": [
                {
                    id: 1,
                    name: "HF2000 High-Pressure Filter",
                    description: "High-pressure capable filter for hydraulic systems",
                    price: 599.99
                },
                {
                    id: 2,
                    name: "HF1500 Return Line Filter",
                    description: "Return line filter with contamination indicator",
                    price: 449.99
                },
                {
                    id: 3,
                    name: "HF1000 Suction Filter",
                    description: "Protective suction strainer for hydraulic pumps",
                    price: 299.99
                },
                {
                    id: 4,
                    name: "HF750 Duplex Filter",
                    description: "Switchable duplex filter for continuous operation",
                    price: 899.99
                }
            ],
            "Air Filters": [
                {
                    id: 1,
                    name: "AF3000 Compressed Air Filter",
                    description: "High-efficiency compressed air filtration",
                    price: 399.99
                },
                {
                    id: 2,
                    name: "AF2000 Coalescing Filter",
                    description: "Oil removal filter for compressed air systems",
                    price: 349.99
                }
            ]
        }
    },
    "Hose And Fitting": {
        title: "Hose And Fitting",
        categories: {
            "Hydraulic Hose, Fittings And Adapters": [
                {
                    id: 1,
                    name: "HH4000 High-Pressure Hose",
                    description: "4-wire spiral hydraulic hose for extreme pressure applications",
                    price: 189.99
                },
                {
                    id: 2,
                    name: "HH2000 Medium Pressure Hose",
                    description: "2-wire braid hose for general hydraulic systems",
                    price: 129.99
                },
                {
                    id: 3,
                    name: "HH1500 Thermoplastic Hose",
                    description: "Lightweight thermoplastic hose for mobile applications",
                    price: 149.99
                },
                {
                    id: 4,
                    name: "HH1000 Suction Hose",
                    description: "Reinforced suction hose for intake lines",
                    price: 169.99
                }
            ],
            "Industrial Fittings": [
                {
                    id: 1,
                    name: "IF2000 JIC Fittings",
                    description: "37° flare fittings for high-pressure connections",
                    price: 24.99
                },
                {
                    id: 2,
                    name: "IF1500 ORFS Fittings",
                    description: "O-ring face seal fittings for leak-free connections",
                    price: 34.99
                },
                {
                    id: 3,
                    name: "IF1000 NPT Fittings",
                    description: "National pipe thread fittings for general applications",
                    price: 19.99
                }
            ],
            "Quick Couplings": [
                {
                    id: 1,
                    name: "QC3000 Flat-Face Coupling",
                    description: "Non-spill flat-face quick disconnect coupling",
                    price: 89.99
                },
                {
                    id: 2,
                    name: "QC2000 Push-to-Connect",
                    description: "Easy-connect coupling for low-pressure applications",
                    price: 49.99
                },
                {
                    id: 3,
                    name: "QC1500 High-Flow Coupling",
                    description: "Large bore coupling for maximum flow rates",
                    price: 69.99
                }
            ]
        }
    },

    "lubrication": {
        title: "Lubrication",
        categories: {
            "Automatic Systems": [
                {
                    id: 1,
                    name: "ALS5000 Central Lubrication System",
                    description: "Automated central lubrication system for industrial machinery",
                    price: 4999.99
                },
                {
                    id: 2,
                    name: "ALS3000 Progressive System",
                    description: "Progressive distribution system for multiple point lubrication",
                    price: 3499.99
                },
                {
                    id: 3,
                    name: "ALS2000 Single-Line System",
                    description: "Single-line automatic lubrication for simple applications",
                    price: 2499.99
                }
            ],
            "Grease Pumps": [
                {
                    id: 1,
                    name: "GP4000 High-Pressure Pump",
                    description: "Electric grease pump for centralized systems",
                    price: 1299.99
                },
                {
                    id: 2,
                    name: "GP2500 Pneumatic Pump",
                    description: "Air-operated grease pump for heavy-duty applications",
                    price: 899.99
                },
                {
                    id: 3,
                    name: "GP1500 Manual Pump",
                    description: "Hand-operated grease pump for maintenance",
                    price: 299.99
                }
            ],
            "Oil Systems": [
                {
                    id: 1,
                    name: "OS3000 Circulation System",
                    description: "Oil circulation system with cooling and filtration",
                    price: 5999.99
                },
                {
                    id: 2,
                    name: "OS2000 Level Control",
                    description: "Automatic oil level control system",
                    price: 1499.99
                },
                {
                    id: 3,
                    name: "OS1500 Mist Lubrication",
                    description: "Oil mist lubrication system for high-speed bearings",
                    price: 2499.99
                }
            ]
        }
    },

    "fluid-process": {
        title: "Fluid Process",
        categories: {
            "Process Pumps": [
                {
                    id: 1,
                    name: "PP5000 Centrifugal Pump",
                    description: "High-efficiency centrifugal pump for process applications",
                },
                {
                    id: 2,
                    name: "PP3000 Diaphragm Pump",
                    description: "Air-operated double diaphragm pump",
                },
                {
                    id: 3,
                    name: "PP2000 Gear Pump",
                    description: "Positive displacement gear pump for viscous fluids",
                },
                {
                    id: 4,
                    name: "PP1500 Drum Pump",
                    description: "Portable pump for drum transfer applications"
                }
            ],
            "Measurement": [
                {
                    id: 1,
                    name: "FM4000 Flow Meter",
                    description: "High-accuracy electromagnetic flow meter",
                },
                {
                    id: 2,
                    name: "FM2500 Mass Flow Meter",
                    description: "Coriolis mass flow meter for precise measurement",
                },
                {
                    id: 3,
                    name: "PT2000 Pressure Transmitter",
                    description: "Digital pressure transmitter with display"
                }
            ],
            "Process Control": [
                {
                    id: 1,
                    name: "PC3000 Control Valve",
                    description: "Smart positioner-equipped control valve",
                },
                {
                    id: 2,
                    name: "PC2000 Batch Controller",
                    description: "Automated batch control system",
                },
                {
                    id: 3,
                    name: "PC1500 pH Controller",
                    description: "Digital pH monitoring and control system"
                }
            ]
        }
    },

    "industrial-supply": {
        title: "Industrial Supply",
        categories: {
            "Safety Equipment": [
                {
                    id: 1,
                    name: "SE5000 Full Face Respirator",
                    description: "Professional-grade full face respirator with multi-gas cartridges",
                },
                {
                    id: 2,
                    name: "SE4000 Safety Glasses",
                    description: "Impact-resistant safety glasses with anti-fog coating",
                },
                {
                    id: 3,
                    name: "SE3000 Hard Hat",
                    description: "Type II hard hat with ratchet suspension",
                }
            ],
            "Hand Protection": [
                {
                    id: 1,
                    name: "HP2000 Cut Resistant Gloves",
                    description: "Level 4 cut-resistant gloves with grip coating",
                },
                {
                    id: 2,
                    name: "HP1500 Chemical Resistant Gloves",
                    description: "Chemical-resistant nitrile gloves for hazardous materials",
                },
                {
                    id: 3,
                    name: "HP1000 Impact Gloves",
                    description: "Impact-resistant gloves for heavy machinery operation",
                }
            ],
            "Facility Maintenance": [
                {
                    id: 1,
                    name: "FM3000 Spill Kit",
                    description: "Universal spill response kit for industrial facilities",
                },
                {
                    id: 2,
                    name: "FM2000 Lock Out/Tag Out Kit",
                    description: "Comprehensive LOTO kit for equipment maintenance",
                }
            ]
        }
    },

    "Automation And Motion Control": {
        title: "Automation And Motion Control",
        categories: {
            "Variable Frequency And Servo Drives": [
                {
                    id: 1,
                    name: "VFD8000 Variable Frequency Drive",
                    description: "High-performance VFD for industrial motors",
                },
                {
                    id: 2,
                    name: "SD5000 Servo Drive",
                    description: "Precision servo drive for motion control applications",
                },
                {
                    id: 3,
                    name: "MD3000 Micro Drive",
                    description: "Compact drive for small motor applications",
                }
            ],
            "Motors": [
                {
                    id: 1,
                    name: "SM4000 Servo Motor",
                    description: "High-precision servo motor with encoder feedback",
                },
                {
                    id: 2,
                    name: "IM3000 Induction Motor",
                    description: "Energy-efficient induction motor for industrial use",
                },
                {
                    id: 2,
                    name: "STM2000 Stepper Motor",
                    description: "High-torque stepper motor for positioning applications",
                }
            ],
            "Controls": [
                {
                    id: 1,
                    name: "PLC5000 Programmable Logic Controller",
                    description: "Advanced PLC with Ethernet connectivity",
                },
                {
                    id: 2,
                    name: "HMI3000 Touch Panel",
                    description: "Color touchscreen HMI for machine interface",
                },
                {
                    id: 3,
                    name: "RC2000 Robot Controller",
                    description: "Multi-axis robot controller with safety features",
                }
            ]
        }
    },

    "industries": {
        title: "Industries",
        categories: {
            "Manufacturing": [
                {
                    id: 1,
                    name: "Smart Factory Solutions",
                    description: "Integrated automation systems for manufacturing",
                },
                {
                    id: 2,
                    name: "Production Line Optimization",
                    description: "Efficiency improvement solutions for production lines",
                },
                {
                    id: 3,
                    name: "Quality Control Systems",
                    description: "Automated quality inspection and control systems",
                }
            ],
            "Oil And Gas": [
                {
                    id: 1,
                    name: "Wellhead Control Systems",
                    description: "Automated control systems for wellhead operations",
                },
                {
                    id: 2,
                    name: "Pipeline Monitoring Solutions",
                    description: "Remote monitoring and control for pipelines",
                },
                {
                    id: 3,
                    name: "Offshore Equipment",
                    description: "Specialized equipment for offshore operations",
                }
            ],
            "Construction": [
                {
                    id: 1,
                    name: "Heavy Equipment Systems",
                    description: "Hydraulic systems for construction machinery",
                },
                {
                    id: 2,
                    name: "Material Handling Solutions",
                    description: "Equipment for construction material handling",
                }
            ]
        }
    },

    "other": {
        title: "Other Solutions",
        categories: {
            "Custom Solutions": [
                {
                    id: 1,
                    name: "Custom System Design",
                    description: "Engineered solutions for specific applications",
                },
                {
                    id: 2,
                    name: "Integration Services",
                    description: "System integration and automation services",
                },
                {
                    id: 3,
                    name: "Retrofit Solutions",
                    description: "Modernization of existing equipment and systems",
                }
            ],
            "Training": [
                {
                    id: 1,
                    name: "Technical Training",
                    description: "Hands-on training for maintenance and operations",
                },
                {
                    id: 2,
                    name: "Safety Certification",
                    description: "Industrial safety training and certification",
                }
            ],
            "Maintenance": [
                {
                    id: 1,
                    name: "Preventive Maintenance",
                    description: "Scheduled maintenance programs for industrial equipment",
                },
                {
                    id: 2,
                    name: "Emergency Repair",
                    description: "24/7 emergency repair services",
                },
                {
                    id: 3,
                    name: "Condition Monitoring",
                    description: "Predictive maintenance through condition monitoring",
                }
            ]
        }
    }
};

export const detailedIndustries = {
    "industrial-oem-mro": {
        title: "Industrial OEM-MRO",
        categories: {
            "Manufacturing Solutions": [
                {
                    id: 1,
                    name: "Smart Factory Integration System",
                    description: "Complete automation solution for manufacturing facilities",
                    features: [
                        "Real-time monitoring",
                        "Predictive maintenance",
                        "Energy optimization"
                    ],
                    applications: [
                        "Assembly lines",
                        "Process manufacturing",
                        "Quality control"
                    ],
                    image: null
                },
                {
                    id: 2,
                    name: "Industrial IoT Platform",
                    description: "Connected platform for industrial equipment monitoring",
                    features: [
                        "Wireless sensors",
                        "Cloud connectivity",
                        "Analytics dashboard"
                    ],
                    applications: [
                        "Equipment monitoring",
                        "Production tracking",
                        "Maintenance scheduling"
                    ],
                    image: null
                }
            ],
            "Maintenance Solutions": [
                {
                    id: 1,
                    name: "Predictive Maintenance System",
                    description: "AI-powered maintenance prediction platform",
                    features: [
                        "Machine learning algorithms",
                        "Condition monitoring",
                        "Automated alerts"
                    ],
                    image: null
                }
            ]
        }
    },
    "mobile-machinery": {
        title: "Mobile Machinery",
        categories: {
            "Construction Equipment": [
                {
                    id: 1,
                    name: "Heavy Equipment Control System",
                    description: "Integrated control system for construction machinery",
                    features: [
                        "Precision control",
                        "Safety interlocks",
                        "Performance monitoring"
                    ],
                    image: null
                }
            ],
            "Agricultural Machinery": [
                {
                    id: 1,
                    name: "Smart Farming Solution",
                    description: "Precision agriculture control system",
                    features: [
                        "GPS guidance",
                        "Variable rate control",
                        "Yield monitoring"
                    ],
                    image: null
                }
            ]
        }
    },
    "oil-and-gas": {
        title: "Oil And Gas",
        categories: {
            "Upstream Solutions": [
                {
                    id: 1,
                    name: "Wellhead Control System",
                    description: "Automated wellhead management solution",
                    features: [
                        "Pressure control",
                        "Flow monitoring",
                        "Safety shutdown"
                    ],
                    image: null
                }
            ],
            "Downstream Solutions": [
                {
                    id: 1,
                    name: "Refinery Process Control",
                    description: "Integrated refinery control system",
                    features: [
                        "Process optimization",
                        "Safety management",
                        "Environmental monitoring"
                    ],
                    image: null
                }
            ]
        }
    }
    // Add more industries as needed
};




