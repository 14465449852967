import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import './backButton.css';

const BackButton = ({ label = 'Back' }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBack = () => {
        // Get current path parts
        const pathParts = location.pathname.split('/').filter(Boolean);
        
        if (pathParts[0] === 'products') {
            if (pathParts.length === 4) {
                // If we're in product detail page (e.g., /products/category/subcategory/product)
                // Go back to category page
                navigate(`/products/${pathParts[1]}/${pathParts[2]}`);
            } else if (pathParts.length === 3) {
                // If we're in subcategory page (e.g., /products/category/subcategory)
                // Go back to category page
                navigate(`/featured/${pathParts[1]}`);
            } else {
                // If we're in category page, go home
                navigate('/');
            }
        } else if (pathParts[0] === 'featured') {
            // If we're in featured page, go home
            navigate('/');
        } else {
            // Default fallback
            navigate(-1);
        }
    };

    return (
        <button className="back-button" onClick={handleBack}>
            <IoArrowBack className="back-button__icon" />
            <span>{label}</span>
        </button>
    );
};

export default BackButton; 