import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { detailedProducts, detailedIndustries } from '../../data';
import './productCategory.css';
import BackButton from '../../components/backButton/BackButton';

const ProductCategory = () => {
    const { category, subcategory } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    const normalizeText = (text) => {
        if (!text) return '';
        return text
            .toLowerCase()
            .replace(/&/g, 'and')
            .replace(/\s+and\s+/g, '-and-')
            .replace(/[,()]/g, '')
            .replace(/\s+/g, '-')
            .trim();
    };

    const isIndustry = category === 'industries';
    const data = isIndustry ? detailedIndustries : detailedProducts;

    let categoryData, subcategoryData;

    if (isIndustry) {
        const industry = Object.entries(data).find(([_, industryData]) => {
            return Object.keys(industryData.categories).some(cat => 
                normalizeText(cat) === normalizeText(subcategory)
            );
        });

        if (industry) {
            const industryData = industry[1];
            categoryData = industryData;
            const categoryMatch = Object.entries(industryData.categories).find(([cat, _]) => 
                normalizeText(cat) === normalizeText(subcategory)
            );
            if (categoryMatch) {
                const [, solutions] = categoryMatch;
                subcategoryData = solutions;
            }
        }
    } else {
        const exactCategoryKey = Object.keys(data).find(key => 
            normalizeText(key) === normalizeText(category)
        );
        categoryData = exactCategoryKey ? data[exactCategoryKey] : null;
        
        if (categoryData) {
            const subcategoryKey = Object.keys(categoryData.categories).find(key => 
                normalizeText(key) === normalizeText(subcategory)
            );
            subcategoryData = subcategoryKey ? categoryData.categories[subcategoryKey] : null;
        }
    }

    if (!categoryData || !subcategoryData) {
        return <div>Category not found</div>;
    }

    const handleItemClick = (item) => {
        const itemSlug = normalizeText(item.name);
        const basePath = isIndustry ? '/industries' : '/products';
        const categoryPath = isIndustry ? subcategory : category;
        const subcategoryPath = isIndustry ? itemSlug : `${normalizeText(subcategory)}/${itemSlug}`;
        
        navigate(`${basePath}/${categoryPath}/${subcategoryPath}`);
    };

    return (
        <div className="product-category__container">
            <BackButton />
            <h1 className="product-category__title">
                {isIndustry ? subcategory : subcategoryData.title || subcategory}
            </h1>
            <div className="product-category__grid">
                {(isIndustry ? subcategoryData : subcategoryData).map((item, index) => (
                    <div 
                        key={index} 
                        className="product-category__item"
                        onClick={() => handleItemClick(item)}
                    >
                        <div className="product-category__content">
                            <h3 className="product-category__item-title">{item.name}</h3>
                            <p className="product-category__description">{item.description}</p>
                            {!isIndustry && item.price && (
                                <p className="product-category__price">
                                    ${typeof item.price === 'number' ? item.price.toFixed(2) : item.price}
                                </p>
                            )}
                        </div>
                        <div className="product-category__image-container">
                            <div className="product-category__image-placeholder" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductCategory;