import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { detailedProducts, detailedIndustries } from '../../data';
import './productDetail.css';
import BackButton from '../../components/backButton/BackButton';

const ProductDetail = () => {
    const { category, subcategory, productId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const normalizeText = (text) => {
        if (!text) return '';
        return text
            .toLowerCase()
            .replace(/&/g, 'and')
            .replace(/\s+and\s+/g, '-and-')
            .replace(/[,()]/g, '')
            .replace(/\s+/g, '-')
            .trim();
    };

    // Check if we're dealing with industries or products
    const isIndustry = category === 'industries';
    const data = isIndustry ? detailedIndustries : detailedProducts;

    // Find the specific item
    let item;
    if (isIndustry) {
        // Find industry solution
        const industry = Object.entries(data).find(([_, industryData]) => {
            return Object.keys(industryData.categories).some(cat => 
                normalizeText(cat) === normalizeText(subcategory)
            );
        });

        if (industry) {
            const industryData = industry[1];
            const categoryMatch = Object.entries(industryData.categories).find(([cat, _]) => 
                normalizeText(cat) === normalizeText(subcategory)
            );
            if (categoryMatch) {
                const [, solutions] = categoryMatch;
                item = solutions.find(solution => 
                    normalizeText(solution.name) === normalizeText(productId)
                );
            }
        }
    } else {
        // Find product (existing logic)
        item = Object.entries(data).reduce((found, [key, categoryData]) => {
            if (found) return found;
            
            const matchingProduct = Object.entries(categoryData.categories).reduce((innerFound, [subKey, products]) => {
                if (innerFound) return innerFound;
                return products.find(p => 
                    normalizeText(p.name) === normalizeText(productId)
                );
            }, null);

            return matchingProduct || found;
        }, null);
    }

    if (!item) {
        return <div>Item not found</div>;
    }

    return (
        <div className="product-detail__container">
            <BackButton label={isIndustry ? "Back to Industries" : "Back to Products"} />
            <div className="product-detail__content">
                <div className="product-detail__image-container">
                    <div className="product-detail__image-placeholder" />
                </div>
                <div className="product-detail__info">
                    <h1 className="product-detail__title">{item.name}</h1>
                    <p className="product-detail__description">{item.description}</p>
                    
                    {/* Show features and applications for industries */}
                    {isIndustry && item.features && (
                        <div className="product-detail__features">
                            <h3>Key Features</h3>
                            <ul>
                                {item.features.map((feature, index) => (
                                    <li key={index}>{feature}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    
                    {isIndustry && item.applications && (
                        <div className="product-detail__applications">
                            <h3>Applications</h3>
                            <ul>
                                {item.applications.map((application, index) => (
                                    <li key={index}>{application}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Show price for products only */}
                    {!isIndustry && item.price && (
                        <p className="product-detail__price">
                            ${typeof item.price === 'number' ? item.price.toLocaleString() : item.price}
                        </p>
                    )}
                    
                    <button 
                        className="product-detail__purchase-btn"
                        onClick={() => navigate('/contact')}
                    >
                        {isIndustry ? 'Contact Us' : 'Purchase'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;